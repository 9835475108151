import {
    DescriptionOptionField,
    DraperyItemDetailsFields,
    DraperyItemDetailsFieldsAction,
} from "../../../redux/modules/my-shop/drapery-item-details-fields-reducer";
import React, { ChangeEvent, Dispatch, FC } from "react";

import { Checkbox } from "@ramble/ramble-ui";
import DetailsPreview from "./details-preview";

interface DetailsDescriptionProps {
    title: string;
    subTitle: string;
    optionsGroupName: string;
    draperyItemDetailsFields: DraperyItemDetailsFields;
    dispatch: Dispatch<DraperyItemDetailsFieldsAction>;
}
interface OptionsCompProps {
    optionsGroupName: string;
    draperyItemDetailsFields: DraperyItemDetailsFields;
    dispatch: Dispatch<DraperyItemDetailsFieldsAction>;
}

const OptionsComp: FC<OptionsCompProps> = ({
    optionsGroupName,
    draperyItemDetailsFields,
    dispatch,
}) => {
    const optionDescriptionGroup = `${optionsGroupName}DescriptionSettings`;
    const options = draperyItemDetailsFields[optionDescriptionGroup];

    const toggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const fieldId = e.currentTarget.id;
        const optionsUpdated = options.map((i: DescriptionOptionField) => {
            const { id } = i;
            if (id === fieldId) {
                return { ...i, isEnabled: !i.isEnabled };
            }
            return i;
        });

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: {
                [optionDescriptionGroup]: optionsUpdated,
            },
        });
    };

    return (
        <div className="flex flex-col mt-2">
            {options.map((o: DescriptionOptionField) => {
                const { id, name, isEnabled } = o;
                
                if ((optionsGroupName === 'sales' && name !== 'Link to Work Order'  && name !== 'Window ID') || optionsGroupName !== 'sales') {
                    return (
                        <div key={id} className="flex flex-row mb-4">
                            <div>
                                <Checkbox
                                    id={id}
                                    checked={isEnabled}
                                    onChange={toggleCheckbox}
                                />
                            </div>
                            <div>{name}</div>
                        </div>
                    );
                }
            })}
        </div>
    );
};

const DetailsDescription: FC<DetailsDescriptionProps> = ({
    title,
    subTitle,
    optionsGroupName,
    dispatch,
    draperyItemDetailsFields,
}) => {
    return (
        <div className="mt-4">
            <span>{title}</span>
            <p className="m-0 text-sm text-twBlue1">{subTitle}</p>
            <div className="flex flex-col lg:flex-row justify-between">
                <OptionsComp
                    optionsGroupName={optionsGroupName}
                    draperyItemDetailsFields={draperyItemDetailsFields}
                    dispatch={dispatch}
                />
                <DetailsPreview title={title} />
            </div>
        </div>
    );
};

export default DetailsDescription;
