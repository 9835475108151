import React, { ChangeEvent, FC, Fragment } from "react";

import { CalculatorRadio } from "../../../calculator/styles";

interface Props {
    title: string;
    id: string;
    isInstaller?: boolean;
    value: string | number | undefined;
    onChange: (section: string, e: ChangeEvent<HTMLInputElement>) => void;
}

const OtherDetailsRadioGroup: FC<Props> = ({
    title,
    id,
    isInstaller = false,
    value,
    onChange,
}) => {
    const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
        console.log("e tuarget", e.currentTarget);
        onChange(id, e);
    };
    return (
        <div className="flex flex-row justify-between items-center h-[52px] px-[10px]">
            <p className="mb-0" style={{ width: "30%" }}>
                {title}
            </p>
            <div
                className="flex flex-row justify-items-end ml-2"
                style={{ width: "70%" }}
            >
                <div
                    className="flex flex-row items-center mr-2"
                    style={{ width: "30%" }}
                >
                    <CalculatorRadio
                        type="radio"
                        value={"Workroom"}
                        checked={value === "Workroom"}
                        className="cursor-pointer"
                        onChange={handleSelect}
                    />
                    <p className="mb-0">Workroom</p>
                </div>
                <div
                    className="flex flex-row items-center overflow-hidden mr-2"
                    style={{ width: "40%" }}
                >
                    <CalculatorRadio
                        type="radio"
                        checked={value === "Installer"}
                        value={"Installer"}
                        className="cursor-pointer"
                        onChange={handleSelect}
                    />
                    <p
                        className="mb-0 overflow-hidden"
                        style={{ lineClamp: 1, textOverflow: "ellipsis" }}
                    >
                        {isInstaller ? (
                            "Installer"
                        ) : (
                            <Fragment>
                                <span>Client</span>
                                <span style={{ marginLeft: 4 }}>(COM)</span>
                            </Fragment>
                        )}
                    </p>
                </div>
                <div
                    className="flex flex-row items-center"
                    style={{ width: "30%" }}
                >
                    <CalculatorRadio
                        type="radio"
                        value={"No Default"}
                        checked={value === "No Default"}
                        className="cursor-pointer"
                        onChange={handleSelect}
                    />
                    <p className="mb-0 col-span-2">No Default</p>
                </div>
            </div>
        </div>
    );
};

export default OtherDetailsRadioGroup;
