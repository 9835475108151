import {
    CalculatorCheckbox,
    CalculatorFieldInput,
    CalculatorFormLabel,
    CalculatorFormWrapper,
    CalculatorRadio,
    CalculatorRadioGroup,
    CalculatorRadioWrapper,
    FabricButton,
    FabricButtonContainer,
    FabricImageBox,
    FabricImageSubBox,
    FabricImageView,
    PrependAppendInput,
    PrependAppendInputGroup,
    RegularInputGroupLabel,
    TooltipPopup,
} from "./styles";
import {
    DraperyOrderDesignEmbellishment,
    DraperyOrderFabric,
    highlightStyle,
} from "./calculator";
import React, { ChangeEvent, PureComponent } from "react";
import SearchSelect, {
    SearchSelectProps,
} from "../../components/UI/search_select/search_select";
import styled, { css } from "../../theme";
import { DraperyModule } from "../../redux";
import Bind from "lodash-decorators/bind";
import { Config } from "../../utils";
import SelectList from "../../components/UI/select-list";
import { WorkroomSetting } from "../../redux/modules/workroom_setting";

interface FormContainerProps {
    uiDisabled?: boolean;
}

const AdditionalFormContainer = styled.div<FormContainerProps>`
    padding: 25px 20px;
    background-color: #d7d8d3;
    width: 100%;
    margin: 30px auto 0 auto;
    ${(props) =>
        props.uiDisabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;

const AdditionalFormBlock = styled.div`
    background-color: #fff;
    width: 100%;
    padding: 15px 20px 60px;
    border-radius: 0.7em;
    margin-top: 25px;

    &:first-child {
        margin-top: 0;
    }
`;

const FabricWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const FormHeader = styled.div`
    padding: 10px 0;
    border-bottom: 1px solid black;
    font-size: 20px;
    color: #338daa;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-bottom: 30px;
    text-transform: uppercase;
`;

const FabricDimensionInputGroup = styled(CalculatorFieldInput)`
    > div:first-child {
        width: 150px;
    }
`;

const CalculatorFabricFieldInput = styled(CalculatorFieldInput)`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
`;

const CalculatorFabricRadioGroup = styled(CalculatorRadioGroup)`
    padding-left: 0;
`;

const CalculatorFabricCheckbox = styled(CalculatorCheckbox)`
    margin-left: 0;
`;

// const SubmitBox = styled.div`
//     width: 80%;
//     background-color: #f9faf9;
//     padding: 15px calc(40px + 5.5%);
//     margin: 0 auto 0 auto;
//     border: 1px solid #d7d8d3;
//     display: flex;
//     align-items: flex-end;
//     flex-direction: column;
// `;

const YardsNeededBox = styled.div`
    margin: auto 0 0 auto;
`;

const AplicationContiner = styled.div`
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    > label {
        min-width: 150px;
    }
`;

const BiasContiner = styled(AplicationContiner)`
    justify-content: inherit;

    > label {
        min-width: 250px;
    }

    > div {
        width: 80%;
        max-width: 80px;
    }
`;

const FabricTabFormLabel = styled.div`
    min-width: 72px;
    margin-right: 10px;
`;

const AddPhotoLabel = styled(RegularInputGroupLabel)`
    color: #338daa;
    text-align: center;
`;

const ApplicationMethodSelectList = styled(SelectList)`
    width: 100%;
    max-width: 200px;
`;

const biasList = ["2”", "3 ½”", "4”", "5”"];
const nBiasList = [2, 3.5, 4, 5];
const appliedTypeList = ["Adhesive", "Machine Stitched", "Hand Sewn"];

export interface Props {
    draperyOrderDesignEmbellishment: DraperyOrderDesignEmbellishment;
    defaultDraperyOrderFabric: DraperyOrderFabric;
    targetArray: string;
    targetArrayIndex: number;
    type: number;
    name?: string;
    showYardsNeeded?: boolean;
    showApplicationMethod?: boolean;
    showFabricWidth?: boolean;
    showRepeatValues?: boolean;
    showCutWidthFabricStrips?: boolean;
    showTrimWidth?: boolean;
    disabled: boolean;
    highlightedSection: string | null | undefined;
    fabricsPatternItems: any;
    workroomSetting?: WorkroomSetting;
    onSearch: SearchSelectProps["onSearch"];
    onAddSupplierByName: SearchSelectProps["onAddByName"];
    openSupplierModalClick(pathId: string, pathName: string): void;
    openImageModal(name: string, option: "add" | "delete"): void;
    openFabricsPatternModalClick(pathId: string, pathName: string): void;

    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    changeTrimValue?(name: string, value: string): void;
    handleInputChangeForString(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void;
    handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
    handleSelectListChange(value: string | number, name?: string): void;
    handleSearchSelectChange(
        id: number,
        name: string,
        pathId: string,
        pathName: string,
        itemId?: number
    ): void;
    handleSearchSelectClear(pathId: string, pathName: string): void;
    onFabricsPatternSearch: SearchSelectProps["onSearch"];
    openEditFabricsPatternModalClick(
        pathName: string,
        itemId?: number | string | null
    ): void;
    orderOriginState?: number;
    orderOriginStateEmb?: number;
    workOrderEditMode: boolean;
    draperyOrder: DraperyModule.DraperyOrder & { clientName: string };
    isCopy: boolean;
    orderType?: string;
}

// tslint:disable-next-line:no-empty-interface
export interface FabricState {
    isChangedTrim: boolean;
    isFabricFromMyShop?: boolean;
}

class FabricsComponent extends PureComponent<Props, FabricState> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            isChangedTrim: false,
            isFabricFromMyShop:
                (props.fabricsPatternItems &&
                    props.fabricsPatternItems.some(
                        (item: any) =>
                            props.draperyOrderDesignEmbellishment
                                .draperyOrderFabric &&
                            item.name ===
                                props.draperyOrderDesignEmbellishment
                                    .draperyOrderFabric.pattern
                    )) ||
                false,
        };

        if (!props.draperyOrderDesignEmbellishment.draperyOrderFabric) {
            props.draperyOrderDesignEmbellishment.draperyOrderFabric =
                this.props.defaultDraperyOrderFabric;
            props.draperyOrderDesignEmbellishment.draperyOrderFabric.name =
                props.name || "";
        }

        if (
            props.draperyOrderDesignEmbellishment.draperyOrderFabric
                .applicationMethod !== undefined
        ) {
            this.props.draperyOrderDesignEmbellishment.appliedType =
                props.draperyOrderDesignEmbellishment.draperyOrderFabric.applicationMethod;
        }
    }
    componentWillReceiveProps(
        nextProps: Readonly<Props>,
        nextContext: any
    ): void {
        if (
            this.props.defaultDraperyOrderFabric.orderOrigin !== 2 &&
            this.props.defaultDraperyOrderFabric.orderOrigin !== 1 &&
            this.props.defaultDraperyOrderFabric.orderOrigin !==
                nextProps.defaultDraperyOrderFabric.orderOrigin
        ) {
            if (
                nextProps.defaultDraperyOrderFabric &&
                nextProps.orderOriginStateEmb &&
                this.props.defaultDraperyOrderFabric &&
                this.props.draperyOrderDesignEmbellishment.id === 0
            ) {
                // this.props.defaultDraperyOrderFabric.orderOrigin =
                //     nextProps.orderOriginStateEmb;
                {
                    this.props.draperyOrderDesignEmbellishment.draperyOrderFabric =
                        nextProps.defaultDraperyOrderFabric;
                    this.props.draperyOrderDesignEmbellishment.draperyOrderFabric.name =
                        this.props.name || "";
                }
            }
        }
        if (
            (nextProps.draperyOrderDesignEmbellishment.draperyOrderFabric &&
                nextProps.draperyOrderDesignEmbellishment.draperyOrderFabric
                    .pattern === undefined) ||
            (nextProps.draperyOrderDesignEmbellishment.draperyOrderFabric &&
                nextProps.draperyOrderDesignEmbellishment.draperyOrderFabric
                    .values.trimWidth)
        ) {
            this.setState({
                isChangedTrim: true,
            });
        }
        if (nextProps.draperyOrderDesignEmbellishment) {
            const isFabricFromMyShop =
                (this.props.fabricsPatternItems &&
                    this.props.fabricsPatternItems.some(
                        (item: any) =>
                            nextProps.draperyOrderDesignEmbellishment
                                .draperyOrderFabric &&
                            item.name ===
                                nextProps.draperyOrderDesignEmbellishment
                                    .draperyOrderFabric.pattern
                    )) ||
                false;
            this.setState({ isFabricFromMyShop });
        }
    }
    componentDidUpdate(
        prevProps: any,
        prevState: Readonly<FabricState>,
        snapshot?: any
    ): void {
        if (
            this.props.draperyOrderDesignEmbellishment.draperyOrderFabric &&
            this.props.draperyOrderDesignEmbellishment.draperyOrderFabric
                .pattern === undefined
        ) {
            this.setState({
                isChangedTrim: false,
            });
        }
        if (
            prevProps.draperyOrderDesignEmbellishment.draperyOrderFabric &&
            prevProps.draperyOrderDesignEmbellishment.draperyOrderFabric.values
                .fabricWidth &&
            !this.state.isChangedTrim
        ) {
            this.updateGlobalState(
                `${this.props.targetArray}.${this.props.targetArrayIndex}.draperyOrderFabric.values.trimWidth`
            );
        }
    }

    public render() {
        const {
            name,
            targetArray,
            targetArrayIndex,
            onSearch,
            onFabricsPatternSearch,
            showFabricWidth,
            showRepeatValues,
            showApplicationMethod,
            showYardsNeeded,
            showCutWidthFabricStrips,
            showTrimWidth,
            draperyOrderDesignEmbellishment,
            highlightedSection,
            fabricsPatternItems,
            workOrderEditMode,
            draperyOrder,
            orderType,
        } = this.props;
        const { draperyOrderFabric } = draperyOrderDesignEmbellishment;

        if (!draperyOrderFabric) {
            return <></>;
        }
        const paternId = `${targetArray}.${targetArrayIndex}.draperyOrderFabric.patternId`;
        const paternName = `${targetArray}.${targetArrayIndex}.draperyOrderFabric.pattern`;

        const img =
            fabricsPatternItems &&
            fabricsPatternItems.filter((item: any) => {
                return item.name === draperyOrderFabric.pattern;
            });

        const allowEdit =
            !workOrderEditMode &&
            !draperyOrderFabric.pattern &&
            draperyOrderFabric.orderOrigin === 1
                ? true
                : !workOrderEditMode && draperyOrderFabric.pattern
                ? false
                : workOrderEditMode && !draperyOrderFabric.pattern
                ? true
                : false;
        if (!draperyOrderFabric.values.trimWidth) {
            draperyOrderFabric.values.trimWidth =
                draperyOrderFabric.values.fabricWidth;
        }
        return (
            <>
                <AdditionalFormContainer uiDisabled={false}>
                    <AdditionalFormBlock>
                        <FabricWrapper className="flex flex-col lg:flex-row lg:w-full">
                            <div className="lg:w-2/3">
                                <FormHeader>{orderType === 'roman-shade-order' && name && name.includes("TRIM FABRIC") ? `${name.replace(" ", " FROM ")}` : name}</FormHeader>

                                <CalculatorFabricFieldInput
                                // style={
                                //     highlightedSection ===
                                //     `${targetArray}.${targetArrayIndex}.draperyOrderFabric.pattern`
                                //         ? highlightStyle
                                //         : {}
                                // }
                                >
                                    <FabricTabFormLabel>
                                        Pattern Name / #
                                    </FabricTabFormLabel>
                                    <SearchSelect
                                        name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.pattern`}
                                        openModalClick={() =>
                                            this.props.openFabricsPatternModalClick(
                                                paternId,
                                                paternName
                                            )
                                        }
                                        searchPage="calculator"
                                        searchType="calculator"
                                        onItemClick={(id, name, _, itemId) => {
                                            this.props.handleSearchSelectChange(
                                                id,
                                                name,
                                                paternId,
                                                paternName,
                                                itemId
                                            );
                                        }}
                                        onSearch={onFabricsPatternSearch}
                                        onAddByName={
                                            this.props.onAddSupplierByName
                                        }
                                        addButtonText="Add New Fabric to My Shop"
                                        placeholder="Please select"
                                        keepKeyword
                                        initialValue={
                                            draperyOrderFabric.pattern || ""
                                        }
                                        onKeywordCleared={() =>
                                            this.props.handleSearchSelectClear(
                                                paternId,
                                                paternName
                                            )
                                        }
                                        disabled={
                                            workOrderEditMode
                                                ? false
                                                : this.props.disabled
                                        }
                                        required={true}
                                        highlightedSection={highlightedSection}
                                    />
                                </CalculatorFabricFieldInput>
                                <CalculatorFabricFieldInput
                                    style={
                                        highlightedSection ===
                                        `${targetArray}.${targetArrayIndex}.draperyOrderFabric.supplierId`
                                            ? highlightStyle
                                            : {}
                                    }
                                >
                                    <FabricTabFormLabel>
                                        <CalculatorFormLabel>
                                            Supplier
                                        </CalculatorFormLabel>
                                    </FabricTabFormLabel>
                                    <SearchSelect
                                        name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.supplierId`}
                                        searchPage="calculator"
                                        searchItem="supplier"
                                        searchType="calculator"
                                        placeholder="Please select"
                                        keepKeyword
                                        onSearch={onSearch}
                                        onAddByName={
                                            this.props.onAddSupplierByName
                                        }
                                        openModalClick={() =>
                                            this.props.openSupplierModalClick(
                                                `${targetArray}.${targetArrayIndex}.draperyOrderFabric.supplierId`,
                                                `${targetArray}.${targetArrayIndex}.draperyOrderFabric.supplierName`
                                            )
                                        }
                                        onItemClick={async (id, name) => {
                                            this.props.handleSearchSelectChange(
                                                id,
                                                name,
                                                `${targetArray}.${targetArrayIndex}.draperyOrderFabric.supplierId`,
                                                (await onSearch(name)).length >
                                                    0
                                                    ? `${targetArray}.${targetArrayIndex}.draperyOrderFabric.supplierName`
                                                    : `${targetArray}.${targetArrayIndex}.draperyOrderFabric.customSupplier`
                                            );
                                            if (
                                                (await onSearch(name))
                                                    .length === 0
                                            ) {
                                                this.props.handleSearchSelectClear(
                                                    `${targetArray}.${targetArrayIndex}.draperyOrderFabric.supplierId`,
                                                    `${targetArray}.${targetArrayIndex}.draperyOrderFabric.supplierName`
                                                );
                                            }
                                        }}
                                        initialValue={
                                            !draperyOrderFabric.customSupplier
                                                ? draperyOrderFabric.supplierName
                                                : draperyOrderFabric.customSupplier
                                        }
                                        onKeywordCleared={() =>
                                            this.props.handleSearchSelectClear(
                                                `${targetArray}.${targetArrayIndex}.draperyOrderFabric.supplierId`,
                                                !draperyOrderFabric.customSupplier
                                                    ? `${targetArray}.${targetArrayIndex}.draperyOrderFabric.supplierName`
                                                    : `${targetArray}.${targetArrayIndex}.draperyOrderFabric.customSupplier`
                                            )
                                        }
                                        disabled={
                                            allowEdit
                                                ? false
                                                : this.props.disabled ||
                                                  this.state.isFabricFromMyShop
                                        }
                                        required={true}
                                    />
                                </CalculatorFabricFieldInput>
                                <CalculatorFormLabel>
                                    Who will order?
                                </CalculatorFormLabel>
                                <CalculatorFabricRadioGroup
                                    style={
                                        highlightedSection ===
                                        `${targetArray}.${targetArrayIndex}.draperyOrderFabric.orderOrigin`
                                            ? highlightStyle
                                            : {}
                                    }
                                >
                                    <CalculatorRadioWrapper>
                                        <CalculatorRadio
                                            type="radio"
                                            id={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.orderer-workroom`}
                                            name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.orderOrigin`}
                                            value="1"
                                            checked={
                                                draperyOrderFabric.orderOrigin ===
                                                    1 ||
                                                (this.props.orderType !== 'roman-shade-order' && this.props.workroomSetting &&
                                                draperyOrderFabric.orderOrigin ===
                                                    -1 &&
                                                this.props.workroomSetting
                                                    .embellishmentsPurchaser ===
                                                    "Workroom"
                                                    ? true
                                                    : undefined)
                                            }
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={
                                                workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                            uiDisabled={
                                                workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                        />
                                        Workroom
                                    </CalculatorRadioWrapper>
                                    <CalculatorRadioWrapper>
                                        <CalculatorRadio
                                            type="radio"
                                            id={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.orderer-COM`}
                                            name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.orderOrigin`}
                                            value="2"
                                            checked={
                                                draperyOrderFabric.orderOrigin ===
                                                    2 ||
                                                (this.props.orderType !== 'roman-shade-order' && this.props.workroomSetting &&
                                                draperyOrderFabric.orderOrigin ===
                                                    -1 &&
                                                this.props.workroomSetting
                                                    .embellishmentsPurchaser ===
                                                    "Installer"
                                                    ? true
                                                    : undefined)
                                            }
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={
                                                workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                            uiDisabled={
                                                workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                        />
                                        Client (COM)
                                    </CalculatorRadioWrapper>
                                </CalculatorFabricRadioGroup>
                                <CalculatorFormWrapper>
                                    {" "}
                                    <CalculatorFormLabel className="isSpecialtyFabricHandling">
                                        <CalculatorFabricCheckbox
                                            id={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.isSpecialtyFabricHandling`}
                                            name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.isSpecialtyFabricHandling`}
                                            checked={
                                                !!draperyOrderFabric.isSpecialtyFabricHandling
                                            }
                                            onChange={
                                                this.props
                                                    .handleInputChangeForBoolean
                                            }
                                            disabled={
                                                allowEdit
                                                    ? false
                                                    : this.props.disabled ||
                                                      this.state
                                                          .isFabricFromMyShop
                                            }
                                            uiDisabled={
                                                allowEdit
                                                    ? false
                                                    : this.props.disabled ||
                                                      this.state
                                                          .isFabricFromMyShop
                                            }
                                        />
                                        Specialty fabric handling
                                    </CalculatorFormLabel>
                                    <TooltipPopup
                                        text={
                                            <>
                                                This is an upcharge for fabric
                                                that is difficult to work with.
                                            </>
                                        }
                                        position="top right"
                                        className="floatRight"
                                        size="250"
                                    />
                                </CalculatorFormWrapper>
                                {showApplicationMethod && (
                                    <AplicationContiner>
                                        <CalculatorFormLabel className="application-name">
                                            Application Method
                                        </CalculatorFormLabel>
                                        {typeof draperyOrderFabric.applicationMethod !==
                                            "undefined" && (
                                            <ApplicationMethodSelectList
                                                label={""}
                                                name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.applicationMethod`}
                                                selectList={appliedTypeList}
                                                valueSelect={
                                                    appliedTypeList[
                                                        draperyOrderFabric
                                                            .applicationMethod
                                                    ]
                                                }
                                                handleClickSelect={
                                                    this
                                                        .onSelectChangeApplication
                                                }
                                                flexDirection={"column"}
                                                smallPadding
                                                disabled={this.props.disabled}
                                                noClearBox
                                                uiDropArrow
                                            />
                                        )}
                                    </AplicationContiner>
                                )}
                                {showFabricWidth && (
                                    <FabricDimensionInputGroup
                                        style={
                                            highlightedSection ===
                                            `${targetArray}.${targetArrayIndex}.draperyOrderFabric.values.fabricWidth`
                                                ? highlightStyle
                                                : {}
                                        }
                                    >
                                        <div>
                                            <CalculatorFormLabel>
                                                Fabric Width
                                            </CalculatorFormLabel>
                                        </div>
                                        <PrependAppendInputGroup uiSmall>
                                            <PrependAppendInput
                                                type="number"
                                                id={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.values.fabricWidth`}
                                                name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.values.fabricWidth`}
                                                value={
                                                    draperyOrderFabric.values
                                                        .fabricWidth !==
                                                    undefined
                                                        ? draperyOrderFabric
                                                              .values
                                                              .fabricWidth
                                                        : ""
                                                }
                                                onChange={
                                                    this.props
                                                        .handleInputChangeForNumber
                                                }
                                                autoComplete="off"
                                                disabled={
                                                    allowEdit
                                                        ? false
                                                        : this.props.disabled ||
                                                          this.state
                                                              .isFabricFromMyShop
                                                }
                                                uiDisabled={
                                                    allowEdit
                                                        ? false
                                                        : this.props.disabled ||
                                                          this.state
                                                              .isFabricFromMyShop
                                                }
                                            />
                                            <RegularInputGroupLabel>
                                                inches
                                            </RegularInputGroupLabel>
                                        </PrependAppendInputGroup>
                                    </FabricDimensionInputGroup>
                                )}
                                {showRepeatValues && (
                                    <>
                                        <FabricDimensionInputGroup
                                            style={
                                                highlightedSection ===
                                                `${targetArray}.${targetArrayIndex}.draperyOrderFabric.values.verticalRepeat`
                                                    ? highlightStyle
                                                    : {}
                                            }
                                        >
                                            <div>
                                                <CalculatorFormLabel>
                                                    Vertical Repeat
                                                </CalculatorFormLabel>
                                            </div>
                                            <PrependAppendInputGroup uiSmall>
                                                <PrependAppendInput
                                                    type="number"
                                                    id={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.values.verticalRepeat`}
                                                    name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.values.verticalRepeat`}
                                                    value={
                                                        draperyOrderFabric
                                                            .values
                                                            .verticalRepeat !==
                                                        undefined
                                                            ? draperyOrderFabric
                                                                  .values
                                                                  .verticalRepeat
                                                            : ""
                                                    }
                                                    onChange={
                                                        this.props
                                                            .handleInputChangeForNumber
                                                    }
                                                    autoComplete="off"
                                                    disabled={
                                                        allowEdit
                                                            ? false
                                                            : this.props
                                                                  .disabled ||
                                                              this.state
                                                                  .isFabricFromMyShop
                                                    }
                                                    uiDisabled={
                                                        allowEdit
                                                            ? false
                                                            : this.props
                                                                  .disabled ||
                                                              this.state
                                                                  .isFabricFromMyShop
                                                    }
                                                />
                                                <RegularInputGroupLabel>
                                                    inches
                                                </RegularInputGroupLabel>
                                            </PrependAppendInputGroup>
                                        </FabricDimensionInputGroup>
                                        <FabricDimensionInputGroup
                                            style={
                                                highlightedSection ===
                                                `${targetArray}.${targetArrayIndex}.draperyOrderFabric.values.horizontalRepeat`
                                                    ? highlightStyle
                                                    : {}
                                            }
                                        >
                                            <div>
                                                <CalculatorFormLabel>
                                                    Horizontal Repeat
                                                </CalculatorFormLabel>
                                            </div>
                                            <PrependAppendInputGroup uiSmall>
                                                <PrependAppendInput
                                                    type="number"
                                                    id={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.values.horizontalRepeat`}
                                                    name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.values.horizontalRepeat`}
                                                    value={
                                                        draperyOrderFabric
                                                            .values
                                                            .horizontalRepeat !==
                                                        undefined
                                                            ? draperyOrderFabric
                                                                  .values
                                                                  .horizontalRepeat
                                                            : ""
                                                    }
                                                    onChange={
                                                        this.props
                                                            .handleInputChangeForNumber
                                                    }
                                                    autoComplete="off"
                                                    disabled={
                                                        allowEdit
                                                            ? false
                                                            : this.props
                                                                  .disabled ||
                                                              this.state
                                                                  .isFabricFromMyShop
                                                    }
                                                    uiDisabled={
                                                        allowEdit
                                                            ? false
                                                            : this.props
                                                                  .disabled ||
                                                              this.state
                                                                  .isFabricFromMyShop
                                                    }
                                                />
                                                <RegularInputGroupLabel>
                                                    inches
                                                </RegularInputGroupLabel>
                                            </PrependAppendInputGroup>
                                        </FabricDimensionInputGroup>
                                        <CalculatorFormLabel
                                            className="isDropRepeat"
                                            style={
                                                highlightedSection ===
                                                `${targetArray}.${targetArrayIndex}.draperyOrderFabric.isDropRepeat`
                                                    ? highlightStyle
                                                    : {}
                                            }
                                        >
                                            <CalculatorFabricCheckbox
                                                id={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.isDropRepeat`}
                                                name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.isDropRepeat`}
                                                checked={
                                                    !!draperyOrderFabric.isDropRepeat
                                                }
                                                onChange={
                                                    this.props
                                                        .handleInputChangeForBoolean
                                                }
                                                disabled={
                                                    allowEdit
                                                        ? false
                                                        : this.props.disabled ||
                                                          this.state
                                                              .isFabricFromMyShop
                                                }
                                                uiDisabled={
                                                    allowEdit
                                                        ? false
                                                        : this.props.disabled ||
                                                          this.state
                                                              .isFabricFromMyShop
                                                }
                                            />
                                            Drop Repeat
                                        </CalculatorFormLabel>
                                    </>
                                )}
                                {showTrimWidth && (
                                    <FabricDimensionInputGroup
                                        style={
                                            highlightedSection ===
                                            `${targetArray}.${targetArrayIndex}.draperyOrderFabric.values.trimWidth`
                                                ? highlightStyle
                                                : {}
                                        }
                                    >
                                        <div>
                                            <CalculatorFormLabel>
                                                Trim width
                                            </CalculatorFormLabel>
                                        </div>
                                        <PrependAppendInputGroup uiSmall>
                                            <PrependAppendInput
                                                type="number"
                                                id={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.values.trimWidth`}
                                                name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.values.trimWidth`}
                                                value={
                                                    draperyOrderFabric.values
                                                        .trimWidth !== undefined
                                                        ? draperyOrderFabric
                                                              .values.trimWidth
                                                        : ""
                                                }
                                                onChange={
                                                    this.handleTrimWidthChange
                                                }
                                                disabled={
                                                    this.props.disabled ||
                                                    this.state
                                                        .isFabricFromMyShop
                                                }
                                                uiDisabled={
                                                    this.props.disabled ||
                                                    this.state
                                                        .isFabricFromMyShop
                                                }
                                                autoComplete="off"
                                            />
                                            <RegularInputGroupLabel>
                                                inches
                                            </RegularInputGroupLabel>
                                        </PrependAppendInputGroup>
                                    </FabricDimensionInputGroup>
                                )}
                                {showCutWidthFabricStrips && (
                                    <BiasContiner>
                                        <CalculatorFormLabel>
                                            Bias cut width of fabric strips
                                        </CalculatorFormLabel>
                                        <SelectList
                                            label={""}
                                            name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.cutWidthFabricStrips`}
                                            selectList={biasList}
                                            valueSelect={
                                                biasList[
                                                    nBiasList.findIndex(
                                                        (item) =>
                                                            item ===
                                                            draperyOrderFabric.cutWidthFabricStrips
                                                    )
                                                ]
                                            }
                                            handleClickSelect={
                                                this.onSelectChangeCutWidth
                                            }
                                            flexDirection={"column"}
                                            smallPadding
                                            disabled={this.props.disabled}
                                            noClearBox
                                            uiDropArrow
                                        />
                                    </BiasContiner>
                                )}
                            </div>

                            <div className="lg:w-1/3 lg:ml-4">
                                <FabricImageBox
                                    style={
                                        !this.props.disabled &&
                                        this.state.isFabricFromMyShop
                                            ? {
                                                  pointerEvents: "none",
                                                  opacity: 0.5,
                                              }
                                            : {}
                                    }
                                >
                                    <FabricImageSubBox>
                                    {(draperyOrder.id || this.props.isCopy) && draperyOrderFabric.imageId ? (
                                            <FabricImageView
                                                src={
                                                    Config.fileStoreUrl +
                                                    draperyOrderFabric.imageId
                                                }
                                            />
                                        ) : !draperyOrder.id && !this.props.isCopy && (img && img[0] && img[0].avatarId && img[0].avatarId.length > 0) ? (
                                            <>
                                                <FabricImageView
                                                    src={
                                                        Config.fileStoreUrl +
                                                            (img[0].avatarId &&
                                                                img[0]
                                                                .avatarId)
                                                    }
                                                />
                                            </>
                                            ) : !draperyOrder.id && !this.props.isCopy && draperyOrderFabric.imageId ? (
                                                <>
                                                    <FabricImageView
                                                        src={Config.fileStoreUrl + draperyOrderFabric.imageId}
                                                    />
                                                </>
                                            ) : (
                                            <>
                                                <AddPhotoLabel>
                                                    ADD OR UPLOAD
                                                </AddPhotoLabel>
                                                <AddPhotoLabel>
                                                    YOUR OWN IMAGE HERE
                                                </AddPhotoLabel>
                                            </>
                                        )}
                                    </FabricImageSubBox>
                                </FabricImageBox>
                                <FabricButtonContainer>
                                    {(workOrderEditMode
                                        ? true
                                        : !this.props.disabled) &&
                                        (allowEdit ||
                                        !this.state.isFabricFromMyShop ? (
                                            <FabricButton
                                                type="button"
                                                onClick={() =>
                                                    this.props.openImageModal(
                                                        `${targetArray}.${targetArrayIndex}.draperyOrderFabric.imageId`,
                                                        draperyOrderFabric.imageId
                                                            ? "delete"
                                                            : "add"
                                                    )
                                                }
                                            >
                                                {draperyOrderFabric.imageId ||
                                                (img &&
                                                    img[0] &&
                                                    img[0].avatarId &&
                                                    img[0].avatarId)
                                                    ? "Delete Image"
                                                    : "Add Image"}
                                            </FabricButton>
                                        ) : (
                                            <FabricButton
                                                type="button"
                                                onClick={() => {
                                                    if (draperyOrderFabric) {
                                                        this.props.openEditFabricsPatternModalClick(
                                                            `${targetArray}.${targetArrayIndex}`,
                                                            draperyOrderFabric.pattern
                                                        );
                                                    }
                                                }}
                                            >
                                                Edit Item in My Shop
                                            </FabricButton>
                                        ))}
                                </FabricButtonContainer>

                                {showYardsNeeded && (
                                    <YardsNeededBox
                                        style={
                                            highlightedSection ===
                                            `${targetArray}.${targetArrayIndex}.draperyOrderFabric.yardsRecommended`
                                                ? highlightStyle
                                                : {}
                                        }
                                    >
                                        <PrependAppendInputGroup uiSmall>
                                            <PrependAppendInput
                                                type="number"
                                                id={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.yardsRecommended`}
                                                name={`${targetArray}.${targetArrayIndex}.draperyOrderFabric.yardsRecommended`}
                                                autoComplete="off"
                                                value={
                                                    draperyOrderFabric.yardsRecommended ===
                                                    undefined
                                                        ? ""
                                                        : draperyOrderFabric.yardsRecommended
                                                }
                                                onChange={
                                                    this.props
                                                        .handleInputChangeForNumber
                                                }
                                                onKeyDown={(evt) =>
                                                    [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                    ].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                disabled={this.props.disabled}
                                                uiDisabled={this.props.disabled}
                                            />
                                            <RegularInputGroupLabel
                                                style={{
                                                    display: "inline-flex",
                                                }}
                                            >
                                                Yards Needed
                                                <TooltipPopup
                                                    text={
                                                        <>
                                                            Please add the total
                                                            yardage needed{" "}
                                                            <span></span> (for
                                                            all windows on this
                                                            order) for this
                                                            custom
                                                            embellishment. QUIPA
                                                            is not able to
                                                            calculate yardage
                                                            for custom entries.
                                                        </>
                                                    }
                                                    position="top right"
                                                    className="floatRight"
                                                    size="250"
                                                />
                                            </RegularInputGroupLabel>
                                        </PrependAppendInputGroup>
                                    </YardsNeededBox>
                                )}

                                {!showYardsNeeded && <YardsNeededBox />}
                            </div>
                        </FabricWrapper>
                    </AdditionalFormBlock>
                </AdditionalFormContainer>
            </>
        );
    }

    @Bind()
    private onSelectChangeApplication(
        value: string | number,
        name?: string
    ): void {
        if (!this.props.draperyOrderDesignEmbellishment.draperyOrderFabric) {
            return;
        }

        let nValue;

        if (String(value) === "Adhesive") {
            nValue = 0;
        } else {
            nValue = appliedTypeList.indexOf(String(value));

            if (nValue === -1) {
                nValue = 0;
            }
        }

        this.props.handleSelectListChange(nValue, name);
        this.props.handleSelectListChange(
            nValue,
            name!.replace("draperyOrderFabric.applicationMethod", "appliedType")
        );
    }
    @Bind()
    private handleTrimWidthChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (this.props.changeTrimValue) {
            this.props.changeTrimValue(name, value);
        }
    }
    private updateGlobalState(name: string): void {
        if (
            this.props.changeTrimValue &&
            this.props.draperyOrderDesignEmbellishment &&
            this.props.draperyOrderDesignEmbellishment.draperyOrderFabric &&
            this.props.draperyOrderDesignEmbellishment.draperyOrderFabric.values
        ) {
            this.props.changeTrimValue(
                name,
                String(
                    this.props.draperyOrderDesignEmbellishment
                        .draperyOrderFabric.values.fabricWidth
                )
            );
        }
    }
    @Bind()
    private onSelectChangeCutWidth(
        value: string | number,
        name?: string
    ): void {
        if (!this.props.draperyOrderDesignEmbellishment.draperyOrderFabric) {
            return;
        }
        const index = biasList.findIndex((item) => item === value);
        if (index !== -1) {
            const nValue = nBiasList[index];
            this.props.handleSelectListChange(nValue, name);
        } else {
            const numericValue = parseFloat(value.toString());
            if (!isNaN(numericValue)) {
                this.props.handleSelectListChange(numericValue, name);
            }
        }
    }
}

export default FabricsComponent;
