import React, { forwardRef, SVGAttributes } from "react";

/**
 * Check Square icon
 */
const CheckSquareIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>(
    () => {
        return (
            <>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6Z"
                        fill="#3B97B1"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.5421 5.43918C16.8233 5.72047 16.9812 6.10193 16.9812 6.49968C16.9812 6.89743 16.8233 7.27889 16.5421 7.56018L9.04207 15.0602C8.76078 15.3414 8.37931 15.4994 7.98157 15.4994C7.58382 15.4994 7.20236 15.3414 6.92107 15.0602L2.42107 10.5602C2.14783 10.2773 1.99664 9.89837 2.00006 9.50508C2.00347 9.11178 2.16123 8.73556 2.43934 8.45745C2.71745 8.17934 3.09367 8.02159 3.48697 8.01817C3.88026 8.01475 4.25916 8.16594 4.54207 8.43918L7.98157 11.8787L14.4211 5.43918C14.7024 5.15797 15.0838 5 15.4816 5C15.8793 5 16.2608 5.15797 16.5421 5.43918Z"
                        fill="white"
                    />
                </svg>
            </>
        );
    }
);
CheckSquareIcon.defaultProps = {
    height: "20px",
    width: "20px",
};

export default CheckSquareIcon;
