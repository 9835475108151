import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";
interface InchesInputProps {
    id: string;
    value?: string | number;
    onChange: (params: ChangeEvent<HTMLInputElement>) => void;
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    isBottom?: boolean;
    className?: string;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    placeholder?: string | undefined;
}

const StyledInput = styled.input`
    ::placeholder {
        color: #C2C2C2; 
    }
    padding-left: 6px;
`;

const SingleInchInput: FC<InchesInputProps> = ({
    id,
    value,
    onChange,
    handleKeyDown,
    isBottom = false,
    className = "",
    onFocus,
    placeholder
}) => {
    return (
        <div
            className={`grid grid-cols-1 divide-x divide-[#E2E2E2] ${
                isBottom ? "" : "border-b border-[#E2E2E2]"
            } h-14`}
        >
            {/* Finished Size */}
            <div className="flex flex-row items-center justify-center p-0 h-14">
                <StyledInput
                    id={id}
                    name={id}
                    value={value}
                    className="mr-2 rounded-lg items-center border max-w-[72px]  px-2 py-[10px] h-9 font-normal border-[#AAAAAA]"
                    type="number"
                    step={0.001}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    data-name={value}
                    onFocus={onFocus}
                    placeholder={placeholder}
                />
                <p className=" text-[#222222] font-normal leading">in.</p>
            </div>
        </div>
    );
};

export default SingleInchInput;
