import { TransitionPopover } from "@ramble/ramble-ui";
import Bind from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import { FaCaretDown, FaCog, FaSignOutAlt, FaUsersCog } from "react-icons/fa";
import styled, { theme } from "../../theme";
import { UserMenuItem } from "./user_menu_item";

export interface UserMenuProps {
    /**
     * Menu label
     */
    label: string;
    /**
     * Admin access
     */
    isAdmin?: boolean;
    /**
     * Item
     * @param val Item value
     */
    onItemClick(val: string): void;
    handleTriggerModal?(): void;
}

const Container = styled.div`
    flex: 0 0 auto;
    font-size: 1.25rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0;
    cursor: pointer;

    &:hover {
        background: #3b97b1;
    }
`;

const DropdownContainerArrow = styled.span`
    display: block;
    width: 0;
    height: 8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #3b97b1;
    margin: -8px auto;
`;

const DropdownContainer = styled.div`
    background: #3b97b1;
    font-weight: 200;
    color: ${theme.getContrastTextColor(theme.colors.primary)};
    z-index: 99;

    &.transition-enter,
    &.transition-appear {
        opacity: 1;
        transform: scaleY(0.1);
        transform-origin: 100% 0;
    }

    &.transition-enter-active,
    &.transition-appear-active {
        transform: scaleY(1);
        transition: transform 0.1s ease;
    }

    &.transition-exit {
        opacity: 1;
        transform: scaleY(1);
        transform-origin: 100% 0;
    }

    &.transition-exit-active {
        opacity: 0.4;
        transform: scaleY(0.1);
        transition: transform 0.1s ease, opacity 0.1s ease;
    }
`;

const CaretContainer = styled.div`
    padding: 0 1.5em 0 10px;
    height: 100%;
    display: flex;
    align-items: center;
`;

const Label = styled.div`
    padding-left: 0.5em;
    height: 100%;
    display: flex;
    align-items: center;
    min-width: 110px;
`;

interface State {
    active: boolean;
}

export class UserMenu extends PureComponent<UserMenuProps, State> {
    public state: State = {
        active: false,
    };

    private closeTimer?: any;

    public render(): JSX.Element {
        const { label, isAdmin } = this.props;
        const { active } = this.state;

        return (
            <Container
                id="user-menu"
                onMouseLeave={this.requestCloseMenu}
                onClick={this.openMenu}
            >
                <Label>{label}</Label>
                <TransitionPopover
                    uiActive={active}
                    uiDistanceAway={30}
                    uiOffset={-10}
                    uiAutoPosition={false}
                    uiTargetEl="user-menu"
                    uiOnRequestClose={this.toggleMenu}
                    uiTransitionTimeouts={100}
                    uiPosition="bottom right"
                >
                    <DropdownContainer onMouseEnter={this.cancelCloseMenu}>
                        <DropdownContainerArrow />
                        <UserMenuItem
                            label="User Settings"
                            value="user_settings"
                            icon={<FaCog />}
                            topMargin="8px"
                            onClick={this.menuItemClick}
                        />
                        <UserMenuItem
                            label="Account Settings"
                            value="account_settings"
                            icon={<FaUsersCog />}
                            onClick={this.menuItemClick}
                        />
                        {isAdmin && (
                            <UserMenuItem
                                label="Admin Settings"
                                value="admin_settings"
                                icon={<FaUsersCog />}
                                onClick={this.menuItemClick}
                            />
                        )}
                        <UserMenuItem
                            label="Feature Requests"
                            value="quipa.canny.io"
                            icon={<FaUsersCog />}
                            onClick={this.menuExternalItemClick}
                        />
                        {/* <UserMenuItem
                            label="Support"
                            value="support"
                            icon={<FaUsersCog />}
                            onClick={this.menuItemSupportClick}
                        /> */}
                        <UserMenuItem
                            label="Logout"
                            value="logout"
                            alignment="left"
                            icon={<FaSignOutAlt />}
                            onClick={this.menuItemClick}
                        />
                    </DropdownContainer>
                </TransitionPopover>
                <CaretContainer>
                    <FaCaretDown />
                </CaretContainer>
            </Container>
        );
    }

    public componentWillUnmount(): void {
        if (this.closeTimer) {
            clearTimeout(this.closeTimer);
        }
    }

    @Bind()
    private openMenu() {
        this.setState({ active: true });
        this.cancelCloseMenu();
    }

    @Bind()
    private requestCloseMenu() {
        if (this.closeTimer) {
            clearTimeout(this.closeTimer);
        }
        this.closeTimer = setTimeout(this.closeMenu, 1000);
    }

    @Bind()
    private cancelCloseMenu() {
        if (this.closeTimer) {
            clearTimeout(this.closeTimer);
            this.closeTimer = undefined;
        }
    }

    @Bind()
    private closeMenu() {
        this.setState({ active: false });
        this.closeTimer = undefined;
    }

    @Bind()
    private toggleMenu(): void {
        if (!this.state.active) {
            this.setState({
                active: !this.state.active,
            });
        }
    }

    @Bind()
    private menuItemClick(val: string): void {
        const { onItemClick, handleTriggerModal } = this.props;
        if (val === 'logout' && handleTriggerModal) {
            handleTriggerModal()
        }
        this.setState({
            active: false,
        });
        onItemClick(val);
    }

    // @Bind()
    // private menuItemSupportClick(): void {
    //     this.setState({
    //         active: false,
    //     });

    //     window.open("https://quipa.crisp.help/", "_blank");
    // }

    @Bind()
    private menuExternalItemClick(): void {
        this.setState({
            active: false,
        });

        window.open("https://quipa.canny.io", "_blank");
    }
}
