import { ClientModule, RootState } from "../redux";
import { DispatchProp, connect } from "react-redux";
import Header, {
    HeaderProps,
} from "../components/UI/header_with_search_select/header";
import React, { PureComponent } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { SortTable, SortTableRowInfo } from "../components/UI/table/sort_table";

import { ClientContainer } from "./client";
import ClientModal from "../containers/client/client_modal";
import { ContentSection } from "../components/content/content_section";
import { PageContent } from "../components/content/page_content";
import { createCrispProfile, getCrispProfile } from "../api/crisp";
import { Crisp } from "crisp-sdk-web";
import { checkIsBilling } from "../api/stripe";
import { ConfirmationModal } from "../modals/confirmation_modal";
import { Checkbox } from "@ramble/ramble-ui";
import { BasicClientInfo } from "../redux/modules/client";
import { getAccount, updateAccount } from "../api/account";
import { getAllClients, searchClients } from "../api/client";
// tslint:disable-next-line:no-empty-interface
interface ClientOwnProps extends RouteComponentProps {}

interface ClientsProps extends ClientOwnProps {
    accountId: number;
    email: string;
    firstName: string;
    lastName: string;
}

interface ClientsModalProps extends ClientOwnProps {
    isOpenModal: boolean;
    handleCloseModal(): void;
}

interface State {
    isAddClientModalOpen: boolean;
    includeInactive: boolean;
    sort: "asc" | "desc";
    clients: SortTableRowInfo[];
    scrollTop?: number;
    isPopUpChecked: boolean;
    isPopModalRequired: boolean;
    isPopUpModalOpen: boolean;
    showModal: string | null;
    isLoading?: boolean;
}

export class Clients extends PureComponent<
    ClientsProps & DispatchProp & ClientsModalProps,
    State
> {
    constructor(props: ClientsProps & DispatchProp & ClientsModalProps) {
        super(props);
        this.handleCheckModal = this.handleCheckModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }
    public state: State = {
        isAddClientModalOpen: false,
        includeInactive: false,
        clients: [],
        sort: "asc",
        isPopModalRequired: false,
        isPopUpChecked: false,
        isPopUpModalOpen: true,
        showModal: "",
        isLoading: false,
    };

    public setFocusFirstFieldOnClientModal: any;

    public async componentDidMount(): Promise<void> {
        const { accountId } = this.props;
        const showModal = localStorage.getItem("showModal");
        this.setState({ showModal: showModal, isLoading: true });

        try {
            if (accountId) {
                const res = await getAccount(accountId);

                if (res && res.isModalOpen)
                    this.setState({
                        isPopUpModalOpen: res.isModalOpen,
                    });
            }
        } catch (error) {
            console.log(error);
            this.setState({ isLoading: false });
        }
        try {
            await checkIsBilling(accountId);
            await createCrispProfile(accountId);
            const profile = await getCrispProfile(accountId);

            if (profile) {
                Crisp.user.setEmail(profile.email);
                Crisp.user.setNickname(`${profile.person.nickname}`);
            }
        } catch (error) {
            console.error(
                "An error occurred during component mounting:",
                error
            );
            this.setState({ isLoading: false });
        } finally {
            await this.loadClients();
            this.setState({ isLoading: false });
        }
    }

    public render(): JSX.Element {
        /* tslint:disable:jsx-alignment */

        return (
            <PageContent>
                {window.location.pathname === "/clients" && (
                    <Header
                        headingText="Clients"
                        addButtonText="Add New Client"
                        inactiveFilter={this.state.includeInactive}
                        onSearch={this.handleSearch}
                        onInactiveFilterChange={this.toggleInactiveFilter}
                        onAddClick={this.showAddClientModal}
                        onItemClick={this.selectClient}
                    />
                )}
                <ContentSection>
                    <Switch>
                        <Route
                            path="/clients/:id"
                            render={(props) => (
                                <ClientContainer
                                    key={props.match.params.id}
                                    clientId={parseInt(
                                        props.match.params.id,
                                        10
                                    )}
                                    onClose={this.closeClient}
                                    onOtherClientChoosed={this.selectClient}
                                    onClientChanged={this.onClientChanged}
                                    gotoDraperyOrder={this.gotoDraperyOrder}
                                    list={this.state.clients}
                                />
                            )}
                        />
                        <Route
                            path="/clients"
                            render={() => (
                                <SortTable
                                    sort={this.state.sort}
                                    onSortingChanged={this.handleSortingChange}
                                    list={this.state.clients}
                                    scrollTop={this.state.scrollTop}
                                    onRowChoosed={this.selectClient}
                                    isLoading={this.state.isLoading}
                                />
                            )}
                        />
                    </Switch>
                    <ClientModal
                        isModalOpen={
                            this.state.isAddClientModalOpen ||
                            this.props.isOpenModal
                        }
                        onModalClose={this.closeAddClientModal}
                        onClientSaved={this.onClientChanged}
                        resetOnClose
                        setFocusFirstField={(fn) =>
                            (this.setFocusFirstFieldOnClientModal = fn)
                        }
                    />
                    {!this.state.isPopUpModalOpen && (
                        <ConfirmationModal
                            uiActive={this.state.showModal === "true"}
                            uiAcceptLabel="Ok"
                            uiHeader="Pop-ups Required"
                            uiDeclineLabel={false}
                            uiOnConfirm={this.handleCloseModal}
                            uiOnClose={() => {}}
                            uiOnRequestClose={() => {}}
                            uiCloseOnOutsideClick={true}
                            uiCloseOnEsc={true}
                            className="pop-up"
                        >
                            <p>
                                QUIPA utilizes pop-ups. Please ensure pop-ups
                                are enabled for{" "}
                                <a href="https://my.quipa.com/">
                                    my.quipa.com{" "}
                                </a>
                                in your browser settings.
                            </p>

                            <Checkbox
                                checked={this.state.isPopUpChecked}
                                onClick={this.handleCheckModal}
                            >
                                {" "}
                                <span
                                    style={{
                                        fontSize: "14px",
                                        position: "relative",
                                        bottom: "1px",
                                    }}
                                >
                                    Do not show this message again
                                </span>
                            </Checkbox>
                        </ConfirmationModal>
                    )}
                </ContentSection>
            </PageContent>
        );
    }

    private closeAddClientModal = () => {
        this.setState({
            isAddClientModalOpen: false,
        });
        this.props.handleCloseModal();
    };

    private showAddClientModal = () => {
        this.setState(
            {
                isAddClientModalOpen: true,
            },
            () => {
                if (this.setFocusFirstFieldOnClientModal) {
                    this.setFocusFirstFieldOnClientModal();
                }
            }
        );
    };

    private closeClient = () => {
        const { history } = this.props;
        history.push("/clients");
    };

    private selectClient = (id: number, scrollTop?: number) => {
        this.setState({ scrollTop });
        const { history } = this.props;
        history.push(`/clients/${id === 0 ? "" : id}`);
    };

    private handleSearch: HeaderProps["onSearch"] = async (search) => {
        const { accountId } = this.props;
        const { includeInactive } = this.state;
        const res = await searchClients({
            text: search,
            accountId,
            includeInactive,
        });
        if (res.error) {
            return [];
        }
        return res.map((r: BasicClientInfo) => ({
            id: r.id,
            displayName: r.displayName,
            parentName: r.parent && r.parent.displayName,
        }));
    };

    private toggleInactiveFilter = (includeInactive: boolean) => {
        this.setState(
            {
                includeInactive,
            },
            () => {
                this.loadClients();
            }
        );
    };

    private onClientChanged = (client: ClientModule.BasicClientInfo) => {
        // need here to just append state with new client
        // if parentId is 0 or not set just append to the general list
        // if parentId is set, need to find the parent client in the clist list (can be possible sub-client) and append it here
        // for now just reload clients
        // if (!parentId) {
        //     this.setState({
        //         clients: [
        //             ...this.state.clients,
        //             client,
        //         ],
        //     });
        // }
        this.loadClients();
    };

    private handleSortingChange = (sort: "asc" | "desc") => {
        this.setState(
            {
                sort,
            },
            () => this.loadClients()
        );
    };

    private async loadClients(): Promise<void> {
        const { accountId } = this.props;
        const { includeInactive } = this.state;
        const res = await getAllClients({
            accountId,
            sort: this.state.sort,
            includeInactive,
        });
        if (!res.error) {
            const convertToSortTableRowInfo = (
                client: ClientModule.BasicClientInfo
            ) => {
                const rowInfo: SortTableRowInfo = client;
                if (client.subClients) {
                    rowInfo.subList = client.subClients.map(
                        convertToSortTableRowInfo
                    );
                }
                return rowInfo;
            };

            this.setState({
                clients: res.map(convertToSortTableRowInfo),
            });
        }
    }

    private gotoDraperyOrder = (
        clientId: number | undefined,
        draperyOrderId?: number,
        clientDisplayName?: string,
        tab?: "summary",
        customOrderType?: string | null,
    ) => {
        const { history } = this.props;
        let draperyItem: any = localStorage.getItem("draperyItem");
        if (draperyItem) {
            draperyItem = JSON.parse(draperyItem);
        } else {
            draperyItem = {};
        }
        draperyItem.from = location.pathname;

        let romanShadeItem: any = localStorage.getItem("romanShadeItem");
        if (romanShadeItem) {
            romanShadeItem = JSON.parse(romanShadeItem);
        } else {
            romanShadeItem = {};
        }
        romanShadeItem.from = location.pathname;

        localStorage.setItem("draperyItem", JSON.stringify(draperyItem));
        localStorage.setItem("romanShadeItem", JSON.stringify(romanShadeItem));

        history.push(`/calculator${customOrderType ? `?type=${customOrderType}` : '?type=drapery-order'}`, {
            clientId,
            draperyOrderId,
            clientDisplayName,
            tab,
            type: customOrderType ? customOrderType : 'drapery-order',
        });
    };
    private handleCheckModal() {
        this.setState({ isPopUpChecked: !this.state.isPopUpChecked });
    }

    private async handleCloseModal() {
        const { accountId } = this.props;

        const res = await updateAccount({
            accountId: accountId,
            isModalOpen: this.state.isPopUpChecked,
        });

        if (res.error) {
            throw new Error(res.message);
        }
        localStorage.setItem("showModal", "false");
        const showModal = localStorage.getItem("showModal");
        this.setState({ showModal: showModal });
    }
}

function mapStateToProps(
    state: RootState,
    ownProps: ClientOwnProps
): ClientsProps {
    const user = localStorage.getItem("quipa-store");
    const email = user ? JSON.parse(user).user.email : "";

    return {
        ...ownProps,
        accountId: parseFloat(localStorage.getItem("accountId") || "0"),
        email: email,
        firstName: localStorage.getItem("firstName") || "",
        lastName: localStorage.getItem("lastName") || "",
    };
}

export const ClientsContainer = connect(mapStateToProps)(Clients);
