import {
    ClientDashboardModule,
    ClientModule,
    NotificationsModule,
    RoomModule,
    RootState,
    WorkOrderModule,
} from "../redux";
import { DispatchProp, connect } from "react-redux";
import {
    EditItemsModal,
    EditItemsModalProps,
    EditValues,
} from "../modals/edit-items-modal";
import ProjectOverviewTable, {
    ProjectOverviewTableProps,
} from "../components/clients/project_overview_table";
import React, { PureComponent, UIEvent } from "react";

import BasicDetail from "../components/UI/basic_detail";
import { CSSTransition } from "react-transition-group";
import ClientModal from "../containers/client/client_modal";
import ClientMoreDetails from "../components/clients/client_more_details";
import LoadingModal from "../modals/loading-modal";
import { SearchSelectProps } from "../components/UI/search_select/search_select";
import { SortTableRowInfo } from "../components/UI/table/sort_table";
import { PopoverScrollContext } from "@ramble/ramble-ui";
import styled from "../theme";
import { Bind } from "lodash-decorators";
import { exportPdf } from "../api/export-pdf";
import { Config } from "../utils";
import { ConfirmationModal } from "../../src/modals/confirmation_modal";
import Divider from "../components/UI/divider/Divider";
import { getRooms } from "../api/room";
// import ClientTabs from "../components/clients/client_tabs";
import { SortTable } from "../components/clients/project_overview_table";
import {
    archiveClientProjectItem,
    deleteClientProject,
    draperyOrderCopy,
    editClientProject,
    getClientApi,
    getClientProjects,
    searchClients,
} from "../api/client";
import { getWorkOrderPDFContent } from "../api/work-order";
import { FadeLoader } from "react-spinners";

const CSSTransitionBox = styled.div`
    .content-enter {
        opacity: 0;
        transform: scale(0.9);
    }

    .content-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
    }

    .content-exit {
        opacity: 1;
    }

    .content-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
    }
`;

const ClientInfo = styled.div`
    height: calc(100vh - 165px);
    overflow: auto;
    width: 100%;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
`;
const defaultEditItemModalTargetValues = () => {
    return {
        roomName: "",
        status: "",
        invoicePaid: "",
        productionStartDate: "",
        productionFinishedDate: "",
        installDate: "",
    };
};

export interface ClientOwnProps {
    /**
     * Client id to display
     */
    clientId: number;
    onClose(): void;
    onOtherClientChoosed(id: number): void;
    onClientChanged(client: ClientModule.Client): void;
    gotoDraperyOrder(
        clientId?: number | undefined,
        draperyOrderId?: number,
        clientDisplayName?: string,
        tab?: "summary" | "setup",
        customOrderType?: string | null,
    ): void;
    list: SortTableRowInfo[];
}

export interface EditItemModalTargetValues {
    roomName: string;
    roomId?: number;
    status: string;
    invoicePaid: string;
    productionStartDate: string;
    productionFinishedDate: string;
    installDate: string;
}

interface ClientProps extends ClientOwnProps {
    accountId: number;
}

interface State {
    isEditClientModalOpen: boolean;
    detailsDisplayed: boolean;
    isNoteEditMode: boolean;
    client?: ClientModule.Client;
    accountId: number;
    clientId: number;
    displayContent: boolean;
    projectList: ClientDashboardModule.ClientProject[];
    roomList: RoomModule.Room[];
    workOrderPdfStatus: "none" | "downloading" | "error";
    workOrderPdfContent?: WorkOrderModule.WorkOrderPdfContent;
    modalKey: number;
    isEditItemsModalOpen: boolean;
    isLoadingOpen: boolean;
    editItemModalTargetValues: EditItemModalTargetValues;
    checkedProjects: number[];
    areaScrollInfo: {
        scrollX: number;
        scrollY: number;
    };
    showArchived: boolean;
    isModalOpen: boolean;
    value: string;
    sortValue: { field: string; direction: "ASC" | "DESC" };
    addSubClient: boolean;
    initialClientState?: boolean;
    parentClients: any[];
}

export class Client extends PureComponent<ClientProps & DispatchProp, State> {
    public state: State = {
        modalKey: 0,
        isEditClientModalOpen: false,
        detailsDisplayed: false,
        isNoteEditMode: false,
        clientId: 0,
        accountId: 0,
        displayContent: false,
        roomList: [
            {
                id: 0,
                name: "",
                description: "",
            },
        ],
        workOrderPdfStatus: "none",
        projectList: [],

        isEditItemsModalOpen: false,
        isLoadingOpen: false,
        editItemModalTargetValues: defaultEditItemModalTargetValues(),
        checkedProjects: [],
        areaScrollInfo: {
            scrollX: 0,
            scrollY: 0,
        },
        showArchived: false,
        isModalOpen: false,
        value: "",
        addSubClient: false,
        parentClients: [],
        sortValue: { field: "", direction: "ASC" },
    };

    public setFocusFirstFieldOnClientModal: any;

    public async componentDidMount(): Promise<void> {
        this.loadProjects();
    }

    public componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.sortValue !== this.state.sortValue) {
            this.loadProjects();
        }
        if (prevState.addSubClient !== this.state.addSubClient) {
            this.setState({
                isEditClientModalOpen: this.state.addSubClient,
            });
        }
    }

    public render(): JSX.Element {
        setTimeout(() => {
            this.setState({ displayContent: true });
        }, 100);
        const { client, projectList, roomList, checkedProjects, showArchived } =
            this.state;
        const { onClose, onOtherClientChoosed } = this.props;
        const numberDelete = projectList.filter((project) =>
            checkedProjects.includes(project.itemId)
        ).length;
        if (!client) {
            return  <FadeLoader
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(50%,-50%)",
                            zIndex: 9999,
                            display: client ? "none" : "block",
                        }}
                        color="#3B97B1"
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />;
        }
        // const pdfFilenamePrefix = `${
        //     client.lastName || client.company || client.firstName || "QUIPA"
        // } WO-`;
        return (
            <CSSTransitionBox>
                <CSSTransition
                    in={this.state.displayContent}
                    timeout={300}
                    classNames="content"
                    unmountOnExit
                >
                    <ClientInfo onScroll={this.onAreaScroll}>
                        <PopoverScrollContext.Provider
                            value={this.state.areaScrollInfo}
                        >
                            {/* {this.state.workOrderPdfStatus === "downloading" &&
                                this.state.workOrderPdfContent && (
                                    <DraperyPdf
                                        filename={`${pdfFilenamePrefix}${this.state.workOrderPdfContent.workOrderNum}`}
                                        workOrderPdfContent={
                                            this.state.workOrderPdfContent
                                        }
                                        onFinished={
                                            this.handleDraperyPdfFinished
                                        }
                                    />
                                )} */}
                            <BasicDetail
                                info={client}
                                detailsDisplayed={this.state.detailsDisplayed}
                                onToggleDetails={this.toggleClientDetails}
                                onGoBack={onClose}
                                onAncestorClick={onOtherClientChoosed}
                                onClick={this.openClientModal}
                            />

                            <ConfirmationModal
                                uiActive={
                                    this.state.isModalOpen &&
                                    this.state.value === "Duplicate"
                                }
                                uiOnConfirm={() =>
                                    this.handleProjectAction("Duplicate")
                                }
                                uiOnClose={() => {
                                    this.setState({
                                        isModalOpen: false,
                                        value: "",
                                    });
                                }}
                                uiOnRequestClose={() => {
                                    this.setState({
                                        isModalOpen: false,
                                        value: "",
                                    });
                                }}
                                uiCloseOnOutsideClick={true}
                                uiCloseOnEsc={true}
                            >
                                <p>
                                    {`Are you sure you want to duplicate ${numberDelete} ${
                                        numberDelete === 1 ? "item" : "items"
                                    }
                                   ?`}
                                </p>
                            </ConfirmationModal>

                            <ConfirmationModal
                                uiActive={
                                    this.state.isModalOpen &&
                                    this.state.value === "Delete"
                                }
                                uiOnConfirm={() =>
                                    this.handleProjectAction("Delete")
                                }
                                uiOnClose={() => {
                                    this.setState({
                                        isModalOpen: false,
                                        value: "",
                                    });
                                }}
                                uiOnRequestClose={() => {
                                    this.setState({
                                        isModalOpen: false,
                                        value: "",
                                    });
                                }}
                                uiCloseOnOutsideClick={true}
                                uiCloseOnEsc={true}
                            >
                                <p>
                                    {`Are you sure you want to delete ${numberDelete} ${
                                        numberDelete === 1 ? "item" : "items"
                                    }
                                   ?`}
                                </p>
                            </ConfirmationModal>

                            <ConfirmationModal
                                uiActive={
                                    this.state.isModalOpen &&
                                    this.state.value === "Archive"
                                }
                                uiOnConfirm={() =>
                                    this.handleProjectAction("Archive")
                                }
                                uiOnClose={() => {
                                    this.setState({
                                        isModalOpen: false,
                                        value: "",
                                    });
                                }}
                                uiOnRequestClose={() => {
                                    this.setState({
                                        isModalOpen: false,
                                        value: "",
                                    });
                                }}
                                uiCloseOnOutsideClick={true}
                                uiCloseOnEsc={true}
                            >
                                <p>
                                    {`Are you sure you want to archive ${numberDelete} ${
                                        numberDelete === 1 ? "item" : "items"
                                    }
                                   ?`}
                                </p>
                            </ConfirmationModal>

                            {this.state.detailsDisplayed && (
                                <ClientMoreDetails
                                    client={{
                                        ...client,
                                        billingDetails: client.billToParent
                                            ? client.billingDetails
                                            : client.billingDetailsDirectly ||
                                              {},
                                    }}
                                    parentClients={this.state.parentClients}
                                    onSubClientClick={onOtherClientChoosed}
                                    createSubClient={this.createSubClient}
                                />
                            )}

                            <Divider />

                            {/* <ClientTabs /> */}
                            <ProjectOverviewTable
                                projectList={projectList}
                                roomList={roomList}
                                checkedProjects={checkedProjects}
                                showArchived={showArchived}
                                isParent={!!client.subClientsIds.length}
                                onNewClientProject={this.handleNewClientProject}
                                onCheckAllRows={this.checkAllRows}
                                onCheckRow={this.checkRow}
                                onItemColumnClick={
                                    this.handleProjectItemClicked
                                }
                                onWorkOrderColumnClick={
                                    this.handleProjectWorkOrderNumClicked
                                }
                                handleNotCheckedBatchRow={
                                    this.handleNotCheckedBatchRow
                                }
                                onEdited={this.updateProjects}
                                client={client}
                                clients={this.props.list}
                                // accountId={this.props.accountId}
                                onActionSelected={this.handleProjectAction}
                                onShowArchiveChange={this.onShowArchiveChange}
                                onOpenModal={this.onOpenModal}
                                loadProjects={this.loadProjects}
                                mapSortTableToSortOptions={
                                    this.mapSortTableToSortOptions
                                }
                                editProjectItem={this.editProjectItem}
                            />
                            <ClientModal
                                key={client.id}
                                client={client}
                                isModalOpen={this.state.isEditClientModalOpen}
                                onModalClose={this.closeEditClientModal}
                                onClientSaved={this.onClientEdited}
                                resetOnClose
                                setFocusFirstField={(fn) =>
                                    (this.setFocusFirstFieldOnClientModal = fn)
                                }
                                onUpdateModalKey={this.updateModalKey}
                                addSubClient={this.state.addSubClient}
                            />
                            <EditItemsModal
                                active={this.state.isEditItemsModalOpen}
                                onRequestClose={this.editItemModalClose}
                                roomList={roomList}
                                onSave={this.batchEdit}
                                clients={this.props.list}
                                handleSearchClient={this.handleSearchClient}
                            />
                            <LoadingModal
                                message="Creating work order..."
                                isModalOpen={this.state.isLoadingOpen}
                                onModalClose={this.toggleLoadingModal}
                            />
                        </PopoverScrollContext.Provider>
                    </ClientInfo>
                </CSSTransition>
            </CSSTransitionBox>
        );
    }
    @Bind()
    async updateModalKey(): Promise<void> {
        this.setState((prevState) => ({ modalKey: prevState.modalKey + 1 }));
        this.loadProjects();
    }
    private checkAllRows = (checked: boolean) => {
        this.setState({
            checkedProjects: checked
                ? this.state.projectList.map((p) => p.itemId)
                : [],
        });
    };

    @Bind()
    private createSubClient(): void {
        this.setState((prev) => {
            return {
                ...prev,
                addSubClient: !this.state.addSubClient,
            };
        });
    }

    private handleSearchClient: SearchSelectProps["onSearch"] = async (
        search: string
    ) => {
        const res = await searchClients({
            text: search,
            accountId: this.props.accountId,
        });
        if (res.error) {
            return [];
        }
        return res.map((r: any) => ({
            id: r.id,
            displayName:
                (r.parent ? r.parent.displayName + " | " : "") + r.displayName,
        }));
    };

    private checkRow = (id: number, checked: boolean) => {
        if (checked) {
            if (this.state.checkedProjects.includes(id)) {
                return;
            }
            this.setState({
                checkedProjects: [...this.state.checkedProjects, id],
            });
        } else {
            this.setState({
                checkedProjects: this.state.checkedProjects.filter(
                    (cid) => cid !== id
                ),
            });
        }
    };

    private batchEdit: EditItemsModalProps["onSave"] = async (newValues) => {
        if (!this.state.checkedProjects.length) {
            return;
        }
        // close modal early. Ideally modal should await for onSave callback and display spinner
        this.setState({ isEditClientModalOpen: false });
        const nonEmptyValues = Object.entries(newValues).reduce(
            (all, [key, val]) => {
                if (val) {
                    all[key] = val;
                }
                return all;
                // tslint:disable-next-line:no-object-literal-type-assertion
            },
            {} as Partial<EditValues>
        );

        const filteredNewProjects = this.state.projectList.filter(
            (i) => !i.archived
        );

        let newProjects = [...filteredNewProjects];
        for (const id of this.state.checkedProjects) {
            const projIdx = newProjects.findIndex((proj) => proj.itemId === id);
            if (projIdx === -1) {
                continue;
            }
            const proj = newProjects[projIdx];
            if (!proj) {
                continue;
            }
            const newProj: ClientDashboardModule.ClientProject = {
                ...proj,
                ...nonEmptyValues,
            };
            if (!newValues.clientId) {
                newProjects[projIdx] = newProj;
            } else {
                const filteredNewProjects = newProjects.filter(
                    (c) => c.itemId !== id
                );
                newProjects = filteredNewProjects;
            }
            await this.editProjectItem(newProj);
        }

        this.setState({
            projectList: newProjects,
            checkedProjects: [],
            isEditItemsModalOpen: false,
        });
        this.loadProjects();
    };

    private editItemModalClose = () => {
        this.setState({ isEditItemsModalOpen: false });
    };

    private toggleClientDetails = () => {
        this.setState({ detailsDisplayed: !this.state.detailsDisplayed });
    };

    private closeEditClientModal = () => {
        this.setState({
            addSubClient: false,
            isEditClientModalOpen: false,
        });
    };

    private openClientModal = () => {
        this.setState(
            {
                isEditClientModalOpen: true,
            },
            () => {
                if (this.setFocusFirstFieldOnClientModal) {
                    this.setFocusFirstFieldOnClientModal();
                }
            }
        );
    };

    private onClientEdited = (client: ClientModule.Client) => {
        this.setState({
            client,
        });
        this.processItems("edit", client.active);
        this.props.onClientChanged(client);
    };

    private handleNewClientProject = (type: string) => {
        const { client } = this.state;
        if (client) {
            this.props.gotoDraperyOrder(client.id, 0, client.displayName, 'setup', type);
        }
    };

    private handleProjectItemClicked = (
        id?: number | undefined,
        itemId?: number,
        item?: string,
        target?: "summary",
        customOrderType?: string | null
    ) => {
        if (id && itemId) {
            this.props.gotoDraperyOrder(id, itemId, item, "summary", customOrderType);
        }
    };

    private handleProjectWorkOrderNumClicked = async (id: number) => {
        const { client, workOrderPdfStatus: draperyPdf } = this.state;
        if (client && draperyPdf === "none") {
            const res = await getWorkOrderPDFContent(id);
            if (res.error) {
                return;
            }
            this.setState(
                {
                    isLoadingOpen: true,
                    workOrderPdfStatus: "downloading",
                    workOrderPdfContent: res,
                },
                async () => {
                    const resPdf: any = await exportPdf(
                        res,
                        Config.fileStoreUrl
                    );
                    if (resPdf.error) return;

                    const buffer = Buffer.from(resPdf.buffer);
                    const blob = new Blob([buffer], {
                        type: "application/pdf",
                    });
                    const url = window.URL.createObjectURL(blob);

                    window.open(url, "_blank");
                    this.handleDraperyPdfFinished(true);
                }
            );
        }
    };

    private handleDraperyPdfFinished = (success: boolean) => {
        const { dispatch } = this.props;
        if (success) {
            if (!this.state.isLoadingOpen) {
                const notification: NotificationsModule.Notification = {
                    id: 0,
                    message: "Work order created successfully!",
                    type: "info",
                };
                dispatch(NotificationsModule.addNotification(notification));
            }
            this.setState({
                workOrderPdfStatus: "none",
                isLoadingOpen: false,
            });
        } else {
            if (!this.state.isLoadingOpen) {
                const notification: NotificationsModule.Notification = {
                    id: 0,
                    message: "Failed to create work order !",
                    type: "error",
                };
                dispatch(NotificationsModule.addNotification(notification));
            }
            this.setState({
                workOrderPdfStatus: "error",
                isLoadingOpen: false,
            });
        }
    };

    private toggleLoadingModal = () => {
        this.setState({ isLoadingOpen: !this.state.isLoadingOpen });
    };

    private updateProjects: ProjectOverviewTableProps["onEdited"] = (
        projects
    ) => {
        const newProjects = [...this.state.projectList];
        for (const proj of projects) {
            const projIdx = this.state.projectList.findIndex(
                (p) => p.itemId === proj.itemId
            );
            if (projIdx === -1) {
                continue;
            }
            newProjects[projIdx] = proj;
            // actually API should support batch editing multiple project at once and we need to await here to handle any errors
            this.editProjectItem(proj);
        }
        this.setState({ projectList: newProjects });
    };

    private editProjectItem = async (
        project: ClientDashboardModule.ClientProject
    ): Promise<void> => {
        const res = await editClientProject(project);

        if (res.error) {
            return;
        }
    };

    private processItems = async (
        action: "archive" | "unarchive" | "duplicate" | "edit" | "delete",
        isActive?: boolean
    ) => {
        const { accountId, clientId } = this.props;
        const { projectList, checkedProjects } = this.state;
        const projectsToProcess = projectList.filter((project) =>
            checkedProjects.includes(project.itemId)
        );

        const clientDb = await getClientApi({clientId})

        // const getProjectsRes = await getClientProjects({
        //     accountId,
        //     clientId,
        //     includeArchived: true,
        //     sort: { field: '', direction: "ASC" },
        // });

        // console.log(getProjectsRes, projectList);
        

        if (projectList.length > 0 && action === "edit" && isActive === true && clientDb.active !== this.state.initialClientState) {
            for (const project of projectList) {
                const res = await archiveClientProjectItem({
                    accountId,
                    draperyOrderId: project.itemId,
                    archived: false,
                });

                if (res.error) {
                    return;
                }
            }
            this.loadProjects();
        }else if (projectList.length > 0 && action === "edit" && isActive === false && clientDb.active !== this.state.initialClientState) {
            for (const project of projectList) {
                const res = await archiveClientProjectItem({
                    accountId,
                    draperyOrderId: project.itemId,
                    archived: action === "edit",
                });

                if (res.error) {
                    return;
                }
            }
            this.loadProjects();
        } else if (projectList.length === 0) {
            
            const { clientId } = this.props;
            const sort = this.state.sortValue;
            const getProjectsRes = await getClientProjects({
                accountId: this.props.accountId,
                clientId,
                includeArchived: true,
                sort,
            });

            if (Array.isArray(getProjectsRes.payload)) {
                for (const project of getProjectsRes.payload) {
                    
                    const res = await archiveClientProjectItem({
                        accountId,
                        draperyOrderId: project.itemId,
                        archived: isActive === false ? true : false,
                    });
                    this.loadProjects();

                    if (res.error) {
                        return;
                    }
                }
            }
        }

        if (!projectsToProcess.length) {
            return;
        }
        for (const project of projectsToProcess) {
            if (
                (action === "archive" && !project.archived) ||
                (action === "unarchive" && project.archived)
            ) {
                const res = await archiveClientProjectItem({
                    accountId,
                    draperyOrderId: project.itemId,
                    archived: action === "archive",
                });
                if (res.error) {
                    return;
                }
            } else if (action === "duplicate") {
                const res = await draperyOrderCopy({
                    accountId,
                    draperyOrderId: project.itemId,
                });

                if (res.error) {
                    return;
                }
            }
        }
        if (action === "delete") {
            for (const project of projectsToProcess) {
                const res = await deleteClientProject(project);

                if (res.error) {
                    return;
                }
            }
        }
        this.setState({
            checkedProjects: [],
            isModalOpen: false,
        });
        this.loadProjects();
    };

    private handleProjectAction = async (value: string): Promise<void> => {
        switch (value) {
            case "Archive": {
                this.processItems("archive");
                return;
            }
            case "Unarchive": {
                this.processItems("unarchive");
                return;
            }
            case "Batch Edit": {
                this.setState({ isEditItemsModalOpen: true });
                return;
            }
            case "Duplicate": {
                this.processItems("duplicate");
                return;
            }
            case "Delete": {
                this.processItems("delete");
                return;
            }
        }
    };

    private loadProjects = async () => {
        const { clientId, accountId } = this.props;
        const sort = this.state.sortValue;
        const { showArchived } = this.state;

        const getProjectsRes = await getClientProjects({
            accountId: this.props.accountId,
            clientId,
            includeArchived: showArchived,
            sort,
        });

        if (getProjectsRes.error) {
            return;
        }
        const projectList: ClientDashboardModule.ClientProject[] =
            getProjectsRes.payload;
        const getRoomListRes = await getRooms(clientId);
        if (getRoomListRes.error) {
            return;
        }
        const roomList = [...this.state.roomList.concat(getRoomListRes)];
        const res = await getClientApi({ clientId, accountId });
        if (res.error) {
            return;
        }
        const client = res;
        this.setState({
            client,
            initialClientState: client.active,
            projectList,
            roomList,
            parentClients: client.parentClients,
        });
    };

    private mapSortTableToSortOptions = (sortTable: SortTable): void => {
        this.setState((prev) => {
            return {
                ...prev,
                sortValue: {
                    field: sortTable.field,
                    direction: sortTable.position === 1 ? "ASC" : "DESC",
                },
            };
        });
    };

    private onAreaScroll = (e: UIEvent<HTMLDivElement>): void => {
        this.setState({
            areaScrollInfo: {
                scrollX: e.currentTarget.scrollLeft,
                scrollY: e.currentTarget.scrollTop,
            },
        });
    };

    private handleNotCheckedBatchRow = (
        id: number,
        project: ClientDashboardModule.ClientProject
    ) => {
        if (project.archived) {
            return;
        }
        if (this.state.checkedProjects.includes(id)) {
            return;
        }
        this.setState({
            checkedProjects: [...this.state.checkedProjects, id],
        });
    };

    private onShowArchiveChange = (isShow: boolean): void => {
        this.setState(
            {
                showArchived: isShow,
            },
            () => {
                this.loadProjects();
            }
        );
    };
    private onOpenModal = (value: string) => {
        this.setState({ isModalOpen: true, value });
    };
}

function mapStateToProps(
    state: RootState,
    ownProps: ClientOwnProps
): ClientProps {
    return {
        ...ownProps,
        accountId: parseFloat(localStorage.getItem("accountId") || "0"),
    };
}

export const ClientContainer = connect(mapStateToProps)(Client);
