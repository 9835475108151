import axiosInstance from "./axios-instance";

const addDraperyOrderFabric = async (payloadRecieved: any) => {
    try {
        let payload = {
            ...payloadRecieved,
            values: {
                ...payloadRecieved.values,
                fabricWidth:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.fabricWidth !== "" &&
                    payloadRecieved.values.fabricWidth !== null &&
                    payloadRecieved.values.fabricWidth >= 0
                        ? parseFloat(payloadRecieved.values.fabricWidth)
                        : payloadRecieved.noValue,
                horizontalRepeat:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.horizontalRepeat !== "" &&
                    payloadRecieved.values.horizontalRepeat !== null &&
                    payloadRecieved.values.horizontalRepeat >= 0
                        ? parseFloat(payloadRecieved.values.horizontalRepeat)
                        : payloadRecieved.noValue,
                verticalRepeat:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.verticalRepeat !== "" &&
                    payloadRecieved.values.verticalRepeat !== null &&
                    payloadRecieved.values.verticalRepeat >= 0
                        ? parseFloat(payloadRecieved.values.verticalRepeat)
                        : payloadRecieved.noValue,
                trimWidth:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.trimWidth !== "" &&
                    payloadRecieved.values.trimWidth !== null &&
                    payloadRecieved.values.trimWidth >= 0
                        ? parseFloat(payloadRecieved.values.trimWidth)
                        : payloadRecieved.noValue,
            },
        };

        const response = await axiosInstance.post<any>(
            "/draperyOrderFabric/add",
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error add drapery order fabric:", error);
        throw error;
    }
};

const editDraperyOrderFabric = async (payloadRecieved: any) => {
    try {
        let payload = {
            ...payloadRecieved,
            values: {
                ...payloadRecieved.values,
                fabricWidth:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.fabricWidth !== "" &&
                    payloadRecieved.values.fabricWidth !== null &&
                    payloadRecieved.values.fabricWidth >= 0
                        ? parseFloat(payloadRecieved.values.fabricWidth)
                        : payloadRecieved.noValue,
                horizontalRepeat:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.horizontalRepeat !== "" &&
                    payloadRecieved.values.horizontalRepeat !== null &&
                    payloadRecieved.values.horizontalRepeat >= 0
                        ? parseFloat(payloadRecieved.values.horizontalRepeat)
                        : payloadRecieved.noValue,
                verticalRepeat:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.verticalRepeat !== "" &&
                    payloadRecieved.values.verticalRepeat !== null &&
                    payloadRecieved.values.verticalRepeat >= 0
                        ? parseFloat(payloadRecieved.values.verticalRepeat)
                        : payloadRecieved.noValue,
                trimWidth:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.trimWidth !== "" &&
                    payloadRecieved.values.trimWidth !== null &&
                    payloadRecieved.values.trimWidth >= 0
                        ? parseFloat(payloadRecieved.values.trimWidth)
                        : payloadRecieved.noValue,
            },
        };

        const response = await axiosInstance.put<any>(
            "/draperyOrderFabric/edit",
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error edit drapery order fabric:", error);
        throw error;
    }
};

const deleteDraperyOrderFabric = async (payload: any) => {
    try {
        const response = await axiosInstance.post<any>(
            "/draperyOrderFabric/delete",
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error delete drapery order fabric:", error);
        throw error;
    }
};

const getDraperyOrderFabricsByIds = async(ids: any) => {
    try{
        const response = await axiosInstance.post<any>("/draperyOrderFabric/get-by-ids", ids);
        return response.data;
    } catch(error){
        console.error("Error get drapery order fabrics:", error);
        throw error;
    }
   
}

export {
    addDraperyOrderFabric,
    editDraperyOrderFabric,
    deleteDraperyOrderFabric,
    getDraperyOrderFabricsByIds
};
