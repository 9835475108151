import React, { FC } from "react";

interface TextCellProps {
    id?: string;
    text: string;
    isBottom?: boolean;
    className?: string;
}

const TextCell: FC<TextCellProps> = ({
    text,
    isBottom = false,
    id,
    className,
}) => {
    return (
        <div
            id={id || ""}
            className={`h-14 mb-0 text-center p-2
            ${isBottom ? "" : "border-b border-[#E2E2E2]"} ${
                className ? "border-none" : ""
            }`}
        >
            <div className="flex h-full items-center justify-center">
                <p className="mb-0 ">{text}</p>
            </div>
        </div>
    );
};

export default TextCell;
