export enum ModalName {
    StandardItem = "StandardItem",
    DraperyItem = "DraperyItem",
    RomanShadeItem = "RomanShadeItem",
    FabricsOrLiningsItem = "FabricsOrLiningsItem",
}

export enum PricingSection {
    None = "None",
    Retail = "Retail",
    Trade = "Trade",
}

export enum PricingType {
    None = "None",
    Retail = "Retail",
    Trade = "Trade",
}

export enum ClientPricingAction {
    Discount = "Discount",
    MarkUp = "Mark-up",
}

export enum DraperyWidthRoundUpType {
    Half = "Half",
    Whole = "Whole",
}

export enum CategoryNames {
    Fabrication = "Fabrication",
}

export enum CategoryIds {
    FabricsAndTrims = 101,
    Linings = 110,
}

export enum ItemNames {
    Drapery = "Drapery",
    Measure = "Measure",
    Consult = "Consult",
    Install = "Install",
    RomanShade = "Roman Shade"
}

export enum ItemClasses {
    Standard = 1,
    Drapery = 2,
    FabricsOrLining = 3,
    RomanShade = 5,
}

export enum ItemTypes {
    Product = 1,
    Service = 2,
}
