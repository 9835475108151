import { BotInfo, BrowserInfo, NodeInfo } from "detect-browser";
import { CategoryNames, ItemNames, ItemTypes } from "../types";
import {
    DraperyItemDetailsFields,
    DraperyItemDetailsFieldsAction,
} from "../../../redux/modules/my-shop/drapery-item-details-fields-reducer";
import {
    FormFields,
    FormFieldsAction,
    FormFieldsPartial,
} from "../../../redux/modules/my-shop/form-fields-reducer";
import {
    FormInput,
    FormLabel,
    RadioInput,
    RadioLabel,
    TextAreaContainer,
} from "../styles";
import React, { ChangeEvent, Dispatch, FC, useEffect } from "react";
import SearchSelect, {
    SearchSelectProps,
} from "../../UI/search_select/search_select";

import { Category } from "../../../interfaces/category";
import DetailsDescription from "./details-description";
import ItemAvatar from "../item-avatar";
import LockedInput from "../locked-input";
import LockedLabel from "../locked-label";
import { Supplier } from "../../../interfaces/supplier";
import { TextArea } from "@ramble/ramble-ui";

interface DetailsProps {
    categories: Category[];
    suppliers: Supplier[];
    supplierName: string;
    supplierId: number  | undefined | null;
    openModalClick?(): void;
    setSupplierId?:(id:number | null) => any;
    setSupplierName?:(name:string) => any;
    browser: BrowserInfo | BotInfo | NodeInfo | null;
    formFields: FormFields;
    draperyItemDetailsFields: DraperyItemDetailsFields;
    dispatch: Dispatch<FormFieldsAction>;
    dispatchItemDetails: Dispatch<DraperyItemDetailsFieldsAction>;
    copyDispatch?: Dispatch<FormFieldsAction>
}

const Details: FC<DetailsProps> = ({
    categories,
    suppliers,
    browser,
    openModalClick,
    formFields,
    supplierId,
    setSupplierId,
    setSupplierName,
    supplierName,
    draperyItemDetailsFields,
    dispatch,
    dispatchItemDetails,
    copyDispatch,
}) => {
    const { categoryName, itemNumber, itemTypeId, notes } =
    formFields;

    const handleSearchSupplier: SearchSelectProps["onSearch"] = async (
        term: string
    ) => {
        try {
            let results = [...suppliers];
            results = results
                .filter((r) => r.active)
                .filter((r) => {
                    if (term && term.length) {
                        return r.displayName
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    }
                    return r;
                });

            return results.map((r) => ({
                id: r.id,
                displayName: r.displayName,
            }));
        } catch (error) {
            console.error("Error searching suppliers:", error);
            return [
                {
                    id: 0,
                    displayName: "",
                },
            ];
        }
    };

    const handleSelectSupplier = (id: number, name: string) => {
        const formFieldUpdates: FormFieldsPartial = {
            supplierId: id,
            supplierName: name,
        };

        if (setSupplierId) {
            setSupplierId(0)
        }
        if (setSupplierName) {
            setSupplierName('')
        }

        dispatch({
            type: "updateFormFields",
            formFieldUpdates,
        });
    };

    const handleFieldChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        let key = e.currentTarget.id;
        let value: string | number = e.currentTarget.value;

        const updatedForm = {
            [key]: value,
        };

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updatedForm,
        });
    };

    useEffect(() => {
        if (categories && categories.length) {
            const fabricCategory = categories.find((c) => {
                return c.name === CategoryNames.Fabrication;
            });
            if (fabricCategory) {
                const updates = {
                    categoryId: fabricCategory.id,
                    categoryName: fabricCategory.name,
                    name: ItemNames.RomanShade,
                    itemTypeId: ItemTypes.Product,
                };

                if (copyDispatch) {
                    copyDispatch({
                        type: "updateFormFields",
                        formFieldUpdates: updates,
                    });
                }

                dispatch({
                    type: "updateFormFields",
                    formFieldUpdates: updates,
                });
            }
        }
    }, [categories]);

    useEffect(() => {
        const formFieldUpdates: FormFieldsPartial = {
            supplierId: supplierId,
            supplierName: supplierName,
        };

        dispatch({
            type: "updateFormFields",
            formFieldUpdates,
        });
    }, [supplierName, supplierId]);

    useEffect(() => {
        if (supplierName === '' && formFields.supplierName !== supplierName ) {
            if (setSupplierName) {
                setSupplierName(formFields.supplierName)
            }
            if (setSupplierId) {
                setSupplierId(formFields.supplierId)
            }
        }
    }, [formFields.supplierName, formFields.supplierId])

    return (
        <div className="flex flex-wrap w-full items-start">
            <div className="w-2/4 mb-6 pr-6">
                <div className="flex items-center font-bold tracking-wider place-content-between mt-4 mb-4 text-twBlue1">
                    ITEM INFORMATION
                </div>
                <div className="flex flex-col mb-2">
                    <FormLabel htmlFor="itemCategoryLocked">Category</FormLabel>
                    <LockedInput
                        id="itemCategoryLocked"
                        text={categoryName}
                        toolTipText="This cannot be edited"
                    />
                </div>
                <div className="flex flex-col mb-2">
                    <FormLabel htmlFor="itemName">Name</FormLabel>
                    <LockedInput
                        id="nameLocked"
                        text={'Roman Shade'}
                        // text={name}
                        toolTipText="This cannot be edited"
                    />
                </div>
                <div className="flex flex-col mb-2">
                    <FormLabel htmlFor="itemNumber">Item #</FormLabel>
                    <FormInput
                        id="itemNumber"
                        name="Item Number"
                        className="px-1.5 py-2"
                        type="text"
                        value={itemNumber}
                        onChange={handleFieldChange}
                        uiReportOnBlur={false}
                        disabled={false}
                        uiDisabled={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
                <div className="mb-2">
                    <FormLabel htmlFor="Itemcategory">
                        Preferred Supplier
                    </FormLabel>
                    <SearchSelect
                        name="myshop.preferredSupplier"
                        onItemClick={(id, supplierName) =>
                            handleSelectSupplier(id, supplierName)
                        }
                        onSearch={handleSearchSupplier}
                        initialValue={supplierName}
                        searchItem="supplier"
                        searchPage="myshop"
                        openModalClick={openModalClick}
                        placeholder="Please select"
                        keepKeyword
                        onKeywordCleared={() => handleSelectSupplier(0, "")}
                        searchType="modal"
                        required={false}
                    />
                </div>
                <div className="mt-4">
                    <LockedLabel
                        text="Type"
                        toolTipText="This cannot be edited"
                    />
                    <RadioLabel className="mb-2">
                        <RadioInput
                            id="itemTypeProduct"
                            name="itemType"
                            type="radio"
                            checked={itemTypeId === 1}
                            value={1}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            disabled={true}
                            required
                        />
                        Product
                    </RadioLabel>
                    <RadioLabel className="mb-2">
                        <RadioInput
                            id="itemTypeService"
                            name="itemType"
                            type="radio"
                            checked={itemTypeId === 2}
                            value={2}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            disabled={true}
                            required
                        />
                        Service
                    </RadioLabel>
                </div>
            </div>
            <ItemAvatar dispatch={dispatch} formFields={formFields} />
            <div className="w-full">
                <DetailsDescription
                    title="Sales Description"
                    subTitle="Select info to include on client-facing forms, such as estimates and invoices."
                    optionsGroupName="sales"
                    draperyItemDetailsFields={draperyItemDetailsFields}
                    dispatch={dispatchItemDetails}
                />

                <DetailsDescription
                    title="Ordering Description"
                    subTitle="Select info to include on supplier-facing forms, such as purchase orders."
                    optionsGroupName="ordering"
                    draperyItemDetailsFields={draperyItemDetailsFields}
                    dispatch={dispatchItemDetails}
                />

                <TextAreaContainer>
                    <span>Internal Notes</span>
                    <p className="m-0 text-sm text-twBlue1">
                        Notes for you and your team only; not visible on any
                        forms
                    </p>
                    <TextArea
                        id="notes"
                        name="notes"
                        value={notes}
                        onChange={handleFieldChange}
                    />
                </TextAreaContainer>
            </div>
        </div>
    );
};

export default Details;
