import React, { ButtonHTMLAttributes } from "react";
import styled, { css } from "../../../theme/index";
import { theme } from "../../../theme";
import AddIcon from "../../../assets/icons/add.svg";

interface AddButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    className?: string;
    rightIcon?: boolean;
    highlightedSection?: string | null;
    name?: string;
    pricingRedesign?: boolean
}

const Button = styled.button<AddButtonProps>`
    border-radius: 8px;
    background-color: #3b97b1;
    border: 0;
    color: #fff;
    margin: 0;
    cursor: pointer;
    outline-color: #fff;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0 16px;
    height: 40px;
    font-weight: 500 !important;
    svg {
        margin: 0 5px 0 0;
    }
    &:hover {
        background-color: #44aecc;
    }
    ${props => props.pricingRedesign && css`
            background-color: transparent;
            color: ${theme.colors.primary};
            height: 40px;
            width: 164px;
            margin-left: 0px !important;
            margin-top: 12px;
            margin-bottom: 8px;
            svg path { 
                stroke: ${theme.colors.primary};
            }
            &:hover {
             background-color: transparent;
        }
    `}
    ${(props) => {
        return (
            props.highlightedSection &&
            props.name &&
            props.highlightedSection === props.name &&
            css`
                background-color: ${theme.colors.errorBackground};
            `
        );
    }}
`;

const AddButton: React.FunctionComponent<AddButtonProps> = ({title, className, ...props}) => (
    <Button {...props} className={className} title="">
        {!props.rightIcon && <AddIcon className="inline-flex"/>}
        {title}
        {props.rightIcon && <AddIcon />}
    </Button>
);

export default AddButton;
